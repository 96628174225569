<template>
    <section class="almacen-materiales-info px-3" v-if="material">
        <titulo-divisor titulo="Datos del material" class="my-4" />
        <div class="row">
            <div class="col-12 col-lg-auto text-center">
                <div class="cont-img wh-151 br-4">
                    <img :src="material.imagen | helper-storage" class="h-100 w-100" alt="">
                </div>
            </div>
            <div class="col-8">
                <p class="f-700 mb-3">{{material.nombre}}</p>
                <p class="f-600 f-14 mb-2">
                    Referencia: <span class="f-300">{{material.referencia}}</span>
                </p>
                <p class="f-600 f-14 mb-2">
                    Tipo: <span class="f-300">{{material.tipo}}</span>
                </p>
                <p class="f-600 f-14 mb-2">
                    Unidad de medida: <span class="f-300">{{material.unidad_medida}}</span>
                </p>
                <p class="f-600 f-14 mb-2">
                    Número de etapas que usan este material: <span class="f-300"> {{ material.etapas }} </span>
                </p>
                <p class="f-600 f-14 mb-2">
                    Espesores:
                    <span class="f-300" v-for="(item, index) in material.espesor" :key="index">
                        {{item.nombre}}{{index != material.espesor.length - 1 ? ', ' : ''}}
                    </span>
                </p>
                <p class="f-600 f-14 mb-2">
                    Proveedor favorito: <span class="f-300"> {{ material.proveedor_favorito }} </span>
                </p>
                <div class="mt-3 bg-f5 p-3 br-10">
                    <p class="f-700 mb-2">Descripción</p>
                    <p class="f-12">{{material.descripcion}}</p>
                </div>
            </div>
        </div>
        <titulo-divisor titulo="Datos adicionales" class="my-4" />
        <div class="row">
            <template v-for="(aditional, index) in material.datos_adicionales">
                <div class="col-12 col-lg-7 my-1" :key="index">
                    <div class="row border-dato br-3 mx-0">
                        <div class="col-lg-3 py-2">
                            <p class="f-12 text-muted2 f-600">{{aditional.pregunta}}</p>
                        </div>
                        <div class="col-lg-9 py-2">
                            <p class="f-12 text-muted2">{{aditional.valor}}</p>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed:{
        ...mapGetters({
            material: 'almacen/materiales/material',
        })
    }
}
</script>

<style lang="scss" scoped>
.almacen-materiales-info{
    .img-material{
        width: 30;
    }
   
}
</style>